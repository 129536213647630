import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // Dashboard anterior
  // {
  //   path: '/dashboard',
  //   name: 'Home',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/home/Dashboard.vue')
  // },
  {
    path: '/dashboard/:date/:name',
    name: 'HomeDevices',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Dashboard.vue'),
    props:true
  },
  {
    path: '/executiveReports',
    name: 'ExecutiveReports',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/ExecutiveReports.vue')
  },
  {
    path: '/historicalReports',
    name: 'HistoricalReports',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/HistoricalReports.vue')
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Alerts.vue')
  },
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "home" */ '../views/login/Login.vue')
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
