import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import JsonExcel from "vue-json-excel";
import VueSession from 'vue-session';
import VueToastify from "vue-toastify";
import CxltToastr from 'cxlt-vue2-toastr'
import Snotify from 'vue-snotify';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faDonate, faFireAlt, faMobile, faSatellite, faSun, faTree, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { faChartPie } from '@fortawesome/free-solid-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { faBolt } from '@fortawesome/free-solid-svg-icons'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { faIndustry } from '@fortawesome/free-solid-svg-icons'
import { faThermometerThreeQuarters } from '@fortawesome/free-solid-svg-icons'
import { faTint } from '@fortawesome/free-solid-svg-icons'
import { faCloud } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { faHdd } from '@fortawesome/free-solid-svg-icons'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons'
import { faTemperatureHigh } from '@fortawesome/free-solid-svg-icons'
import { faTemperatureLow } from '@fortawesome/free-solid-svg-icons'
import { faWind } from '@fortawesome/free-solid-svg-icons'
import { faToggleOn } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons'
import { faFlask } from '@fortawesome/free-solid-svg-icons'
import { faChargingStation } from '@fortawesome/free-solid-svg-icons'
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
import { faCloudversify } from '@fortawesome/free-brands-svg-icons'
import { faCompass } from '@fortawesome/free-solid-svg-icons'
import { faCloudShowersHeavy } from '@fortawesome/free-solid-svg-icons'
import { faCloudSunRain } from '@fortawesome/free-solid-svg-icons'
import { faListAlt } from '@fortawesome/free-solid-svg-icons'
import { faListUl } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faGasPump } from '@fortawesome/free-solid-svg-icons'
import { faBurn } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret)
library.add(faLightbulb)
library.add(faClock)
library.add(faCalendar)
library.add(faChartPie)
library.add(faDatabase)
library.add(faBolt)
library.add(faVolumeUp)
library.add(faIndustry)
library.add(faThermometerThreeQuarters)
library.add(faTint)
library.add(faCloud)
library.add(faCircle)
library.add(faFileExcel)
library.add(faBell)
library.add(faHdd)
library.add(faCog)
library.add(faChevronCircleRight)
library.add(faMobile);
library.add(faMobileAlt)
library.add(faCheckCircle)
library.add(faTimesCircle)
library.add(faMapMarkedAlt)
library.add(faTemperatureHigh)
library.add(faTemperatureLow)
library.add(faWind)
library.add(faToggleOn)
library.add(faTimes);
library.add(faLaptopHouse)
library.add(faEyeDropper)
library.add(faFlask);
library.add(faChargingStation);
library.add(faTachometerAlt);
library.add(faCloudversify);
library.add(faCompass);
library.add(faCloudShowersHeavy);
library.add(faCloudSunRain);
library.add(faListAlt);
library.add(faListUl)
library.add(faLock);
library.add(faGasPump);
library.add(faBurn);
library.add(faSun);
library.add(faFireAlt);
library.add(faSatellite);
library.add(faLightbulb);
library.add(faClock);
library.add(faCalendar);
library.add(faIndustry);
library.add(faTree);
library.add(faDonate);
library.add(faArrowLeft);

Vue.use(VueSession)
Vue.use(VueToastify);
Vue.use(Snotify);
Vue.use(Toast);

var toastrConfigs = {
  position: 'bottom right',
  showDuration: 1000,
  hideDuration: 1000,
  timeOut: 4000
}

Vue.use(CxltToastr, toastrConfigs)
Vue.component("downloadExcel", JsonExcel);
Vue.component('font-awesome-icon', FontAwesomeIcon)

//axios.defaults.baseURL = 'http://127.0.0.1:8000/api' //local
axios.defaults.baseURL ='https://iotsolution.cloud/IOTAPI/public/api' //prod

window.axios = axios;
Vue.config.productionTip = false
Vue.config.silent = true

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
