import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        mensaje: "Verificacion",
        token: "",
        user: "",
        mensaje_user: "",
        switch: true,
        banderaNotificaciones: "",
        closeModal : false,
        alertConfig: {
            emails: [],
            sms: [],
            alertEnable: false,
            alertsEmailEnable: false,
            alertsSmsEnable: false,
            alertsNotification: false,
            alertsScreen: false,
            min: 0,
            max: 0,
        },
    },
    getters: {
        getMensaje(state) {
            return state.mensaje;
        },
        getUser(state) {
            return state.user;
        },
        getToken(state) {
            return state.token;
        },
        getMensajeUser(state) {
            return state.mensaje_user;
        },
        getSwitch(state) {
            return state.switch;
        },
        getBanderaNotificacion(store) {
            return state.banderaNotificaciones;
        },
        getAlertConfig(state) {
            return state.alertConfig;
        },
        getCloseModal(state){
            return state.closeModal
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },

        SET_USER(state, data) {
            state.user = data;
        },

        SET_MENSAJE_USER(state, data) {
            state.mensaje_user = data;
        },

        SET_SWITCH(state, data) {
            state.switch = data;
        },

        SET_BANDERA_NOTIFICACIONES(state, data) {
            state.banderaNotificaciones = data;
        },

        SET_ALERT_CONFIG(state, data) {
            state.alertConfig = data;
        },

        SET_CLOSE_MODAL(state,status){           
            state.closeModal = status
        }
    },
    actions: {
        async signIn({ dispatch }, login) {
            let credenctials = { user: login.user, password: login.password, isAdmin: login.isAdmin };
            console.log("Credenciales pasaron");
            try {
                let response = await axios.post(
                    "auth/loginFromPlatform",
                    credenctials
                );
                //console.log(response);
                if (response.data.intRes == 200) {
                    return dispatch("attemp", response.data);
                } else if (response.data.intRes == 300) {
                    console.log("conexion buena, usuario deshabilitado");
                    return dispatch(
                        "actionSetMensajeUser",
                        response.data.mensaje
                    );
                } else if (response.data.intRes == 400) {
                    console.log("conexion buena, contraseña incorrecta");
                    return 2;
                } else if (response.data.intRes == 500) {
                    console.log(
                        "conexion buena, existe usuario pero no tiene los privilegios"
                    );
                    return 3;
                } else if (response.data.intRes == 600) {
                    console.log("conexion buena, no existe usuario");
                    return 4;
                }
            } catch (e) {
                console.log("failed: " + e);
                //console.log('error');
                return null;
            }
        },

        async attemp({ commit }, data) {
            if (data.user) {
                commit("SET_USER", data.user);
                commit("SET_TOKEN", data.token);
            }
            try {
                commit("SET_USER", data.user);
                commit("SET_TOKEN", data.token);
                return 200;
            } catch (e) {
                console.log("failed: " + e);
                commit("SET_TOKEN", null);
                commit("SET_USER", null);
            }
        },

        signOut({ commit }) {
            return axios
                .post("auth/logoutPlatform")
                .then(() => {
                    commit("SET_TOKEN", null);
                    commit("SET_USER", null);
                    localStorage.idUser = "";
                    localStorage.session_p = 0;
                    localStorage.token_p = "";
                })
                .catch(function(error) {
                    alert(error);
                });
        },

        actionSetUser({ commit }, user) {
            commit("SET_USER", user);
        },

        actionSetMensajeUser({ commit }, mensaje_user) {
            commit("SET_MENSAJE_USER", mensaje_user);
            return 1;
        },

        actionSetSwitch({ commit }, switch_data) {
            commit("SET_SWITCH", switch_data);
        },

        actionSetBanderaNotificaciones({ commit }, bandera_notificaciones) {
            commit("SET_BANDERA_NOTIFICACIONES", bandera_notificaciones);
        },

        actionSetAlertConfig({ commit }, alertConfig) {
            commit("SET_ALERT_CONFIG", alertConfig);
        },
        closeModalAlerts( { commit },status){
            commit("SET_CLOSE_MODAL",status);
        }
    },
});
